@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@layer base {
  @font-face {
    font-family: "Mode G";
    font-weight: 500;
    src: url("./modeg.ttf") format("truetype");
  }
  body {
    @apply m-0 font-modeG;
  }
  p,
  textarea,
  input {
    @apply font-poppins;
  }
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* react-bootstrap's ProgressBar */
.progress-bar {
  background-color: #c98dff;
  /* When the bar goes from full "starting" to downloading,
     it moves too slow an looks wierd */
  transition: none !important;
}
.progress {
  height: 1.1rem;
}
